import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Heading1New,
  ThemedButtonNew,
  ErrorCrossCircleNew,
  ErrorCrossCircleRocket,
  Heading1Rocket,
  BodyTextRocket,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { continueRule } from "../continue/ContinueSlice";
import { RootState } from "../../store/Store";
import {
  getErrorContinueText,
  getErrorMessage,
} from "../application-error/ApplicationErrorUtils";
import {
  dataLayerGenericInlineErrorWithArray,
  dataLayerSecureLoginErrorPage,
} from "../../tracking/tracking";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

const SecureLoginErrorPage = function () {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  const { errorCode } = useSelector(
    (state: RootState) => state.applicationError
  );

  const errorMessage = getErrorMessage(errorCode);

  useEffect(() => {
    if (clientName) {
      dataLayerSecureLoginErrorPage(clientName);
    }
    if (errorMessage) {
      dataLayerGenericInlineErrorWithArray([errorMessage]);
    }
  }, [clientName]);

  const btnText = getErrorContinueText(errorCode);

  const crossCircle = isRocketEnabled ? (
    <ErrorCrossCircleRocket role="presentation" />
  ) : (
    <ErrorCrossCircleNew role="presentation" />
  );

  const header = isRocketEnabled ? (
    <Heading1Rocket>We can't log you in</Heading1Rocket>
  ) : (
    <StyledHeading1New>We can't log you in</StyledHeading1New>
  );

  const message = isRocketEnabled ? (
    <MessageRocket data-testid="error-message-rocket">
      {errorMessage}
    </MessageRocket>
  ) : (
    <Message data-testid="error-message">{errorMessage}</Message>
  );

  const themedButton = isRocketEnabled ? (
    <ThemedButtonRocket
      isFullWidth
      label={btnText}
      data-testid="back-button-rocket"
      className="sentry-unmask"
      onClick={(e) => {
        e.preventDefault();
        dispatch(continueRule());
      }}
    />
  ) : (
    <StyledButton
      data-testid="back-button"
      onClick={() => {
        dispatch(continueRule());
      }}
    >
      {btnText}
    </StyledButton>
  );

  return (
    <MainDiv>
      {crossCircle}
      {header}
      {message}
      {themedButton}
    </MainDiv>
  );
};

export default SecureLoginErrorPage;

const MainDiv = styled.div`
  margin: 40px 10px;
  text-align: center;
`;

const StyledHeading1New = styled(Heading1New)`
  margin-bottom: 8px;
`;

const Message = styled.p`
  margin-top: 0px;
  margin-bottom: 24px;
`;

const StyledButton = styled(ThemedButtonNew)`
  width: 100%;
`;

const MessageRocket = styled(BodyTextRocket).attrs<{
  className: string;
  children: React.ReactNode;
}>({
  variant: "body_300",
})`
  ${rdsMargin.s3.top};
  ${rdsMargin.s7.bottom};
`;
