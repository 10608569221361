import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { continueRule } from "../continue/ContinueSlice";
import { setShowLoader } from "../loader/LoaderSlice";
import {
  BodyTextRocket,
  Heading1New,
  Heading1Rocket,
  SuccessTickCircleNew,
  SuccessTickCircleRocket,
  ThemedButtonNew,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { dataLayerFlybuysLinkedPage } from "../../tracking/tracking";
import { RootState } from "../../store/Store";
import useRocketEnabled from "../../common/hooks/useRocketEnabled";
import { rdsMargin } from "@coles/rocket";

const FlybuysLinkedPage = () => {
  const dispatch = useDispatch();

  const clientName = useSelector(
    (state: RootState) => state.channel.clientName
  );

  useEffect(() => {
    if (clientName) {
      dataLayerFlybuysLinkedPage(clientName);
    }
  }, [clientName]);

  useEffect(() => {
    dispatch(setShowLoader(false));
  }, []);

  const isRocketEnabled = useRocketEnabled();

  return isRocketEnabled ? (
    <Container>
      <SuccessTickCircleRocket
        data-testid="success-tick-rocket"
        role="presentation"
      />
      <HeaderRocket>Flybuys linked</HeaderRocket>
      <DescriptionRocket>
        Enjoy access to your points across all your connected Coles Group
        accounts, managed anytime from one secure location.
      </DescriptionRocket>
      <ThemedButtonRocket
        isFullWidth
        label="Continue"
        data-testid="continue-button-rocket"
        className="sentry-unmask"
        onClick={(e) => {
          e.preventDefault();
          dispatch(continueRule());
        }}
      />
    </Container>
  ) : (
    <Container>
      <SuccessTickCircleNew data-testid="success-tick" role="presentation" />
      <Header>Flybuys linked</Header>
      <Description>
        Enjoy access to your points across all your connected Coles Group
        accounts, managed anytime from one secure location.
      </Description>
      <ThemedButtonNew
        isFullWidth={true}
        onClick={(e) => {
          e.preventDefault();
          dispatch(continueRule());
        }}
      >
        Continue
      </ThemedButtonNew>
    </Container>
  );
};

export default FlybuysLinkedPage;

const Container = styled.div`
  text-align: center;
`;

const Header = styled(Heading1New)`
  margin: 28px 0 16px 0;
`;

const HeaderRocket = styled(Heading1Rocket)`
  ${rdsMargin.s6.top};
  ${rdsMargin.s4.bottom};
`;

const Description = styled.p`
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const DescriptionRocket = styled(BodyTextRocket).attrs<{
  className: string;
  children: React.ReactNode;
}>({
  variant: "body_300",
})`
  ${rdsMargin.none.top};
  ${rdsMargin.s7.bottom};
`;
